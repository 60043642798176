<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Egreso Extraordinario - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>

                <b-col md="6">
                  <b-form-group>
                    <label>Proveedor: </label>
                    <v-select disabled class="w-100" :filterable="false" label="name" v-model="provider" :options="providers"></v-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Comprobante :">
                    <b-form-select disabled v-model="expense.type_invoice" :options="invoices_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Documento :">
                    <b-form-input disabled type="text" ref="serie" v-model="expense.number"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Emisión:">
                    <b-form-input disabled type="date" ref="date" v-model="expense.broadcast_date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Caja:">
                    <b-form-input class="text-center" disabled v-model="expense.box"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago: ">
                    <b-form-select disabled  v-model="expense.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                

               

                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select disabled ref="coin" v-model="expense.coin" :options="coins"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Observación:">
                    <b-form-input disabled type="text" v-model="expense.observation"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Importe:">
                    <b-form-input type="number" disabled class="text-right" step="any" v-model="expense.total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/egreso-extraordinario/listar' }" append >REGRESAR</b-link>

                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <ModalProviders />
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalProviders from '@/views/components/ModalProvider'
import LoadingComponent from './../pages/Loading'

export default {
  name: "IncomeEdit",
  props: ["id_extraordinary_expense"],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalProviders,
      vSelect,
  },
  data() {
    return {
      isLoading: false,
      module: 'ExtraordinaryExpense',
      role: 5,
      expense: {
          id_user:'',
          id_provider:'',
          id_box:'',
          linkage:[],
          type_invoice:'01',
          serie:'',
          number:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_method: '008',
          coin:'PEN',
          observation:'',
          total:(0).toFixed(2),
          state:1,
      },

      providers: [],
      provider:null,

      invoices_type:[
        {value: "01", text : "Factura"},
        {value: "03", text : "Boleta de Venta"},
        {value: "04", text : "Liquidación de compra"},
        {value: "07", text : "Nota de crédito"},
        {value: "08", text : "Nota de débito"},
        {value: "09", text : "Guía de remisión - Remitente"},
        {value: "12", text : "Ticket o cinta emitido por máquina registradora"},
        {value: "22", text : "Comprobante por Operaciones No Habituales"},
        {value: "31", text : "Guía de Remisión - Transportista"},
        {value: "50", text : "Declaración Única de Aduanas - Importación definitiva"},
        {value: "52", text : "Despacho Simplificado - Importación Simplificada"},
        {value: "91", text : "Comprobante de No Domiciliado"},
        {value: "NE", text : "Nota de Entrada"},
        {value: "00", text : "Otros"},
      ],

      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
        {value: "CLP", text : "Pesos Chilenos"},
      ],

      payment_method: [
          {value :"008", text :'EFECTIVO'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"005", text :'TARJETA'},
          // {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"222", text :'YAPE'},
          {value :"333", text :'PLIN'},
      ],

      boxes:[],
      //errors
      errors: {
        id_provider: false,
        type_invoice: false,
        id_box: false,
        number: false,
        broadcast_date: false,
        coin: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewExpense();
  },
  methods: {
    ViewExpense,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


//ver usuario
function ViewExpense() {
  let id_extraordinary_expense = je.decrypt(this.id_extraordinary_expense);
  let me = this;
  let url = this.url_base + "extraordinary-expense/view/" + id_extraordinary_expense;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.expense.id_extraordinary_expense = response.data.result.id_extraordinary_expense;
        me.expense.id_provider = response.data.result.id_provider;
        me.expense.id_box = response.data.result.id_box;
        me.expense.box = response.data.result.box;
        me.expense.type_invoice = response.data.result.type_invoice;
        me.expense.number = response.data.result.number;
        me.expense.broadcast_date = response.data.result.broadcast_date;
        me.expense.linkage = response.data.result.linkage;
        me.expense.payment_method = response.data.result.payment_method;
        me.expense.coin = response.data.result.coin;
        me.expense.total = response.data.result.total;
        me.expense.observation = response.data.result.observation;
        me.expense.state = response.data.result.state;
        me.provider = {id: response.data.result.id_provider, name: response.data.result.provider_name + " - "+response.data.result.provider_document_number}
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text:"A ocurrido un error", timer: 3000,})
      me.isLoading = false;
    });
}

</script>
